// noinspection JSUnresolvedVariable

const {
    parseClone,
    makeImageClone,
    findMinimalOrMaximalPrice,
    findFlatsExactLocale,
    filterAllAllowedProducts,
    buildNeededData,
    imageAddFields,
    minQuantityFieldAdd
} = require("./helpers")

const settingConstants = require("./constants")
const {Get_Exact_Core_Config} = require("./SettingsController")
const {Get_Needed_Attribute} = require("./AttributeController")
const {featuredOrNewAggregate} = require("./aggregations")
const {filterBy} = require("../scripts/filterBy")
const {colorType} = require("../services/color");
// const defaultPicture = require("../images/defaultpic.webp").default;

const productCurrencyToggle = (el, selectedRate, locale) => {
    const { exchange_rate: { rate = 1 } = {} } = selectedRate || {}
    const handlePriceName = (priceKey) => {
        const findItem = el.flats.find(ii => ii.locale === locale)[priceKey];
        if (!!Number(findItem)) {
            return (findItem * rate).toFixed(2)
        }
        return null
    }

    return {
        ...el,
        flats: [
            {
                ...el.flats.find(ii => ii.locale === locale),
                selectedRate,
                price: handlePriceName("price"),
                min_price: handlePriceName("min_price"),
                max_price: handlePriceName("max_price"),
                special_price: handlePriceName("special_price")
            }
        ]
    }
}

const addMinQuantityController = async (models, productArray) => {
    const minQuantityAttribute = await Get_Needed_Attribute(models, "min_qty", "min_qty")
    const minQuantityAttrValue = await models.product_attribute_values.find({attribute_id: minQuantityAttribute?.min_qty[0]?.id})
    return minQuantityFieldAdd(productArray, minQuantityAttrValue)
}

const allowOutOfStock = async (models) => await Get_Exact_Core_Config(models, settingConstants.Out_Of_Stock)

const getNewFeatured = async (models, locale, selectedRate, isMobile) => {
    const allowResponse = await allowOutOfStock(models);
    if (!allowResponse) return []
    return await models.products_v2.aggregate([
        {$match: {"flats.locale": locale, "flats.status": 1}},
        {$sort: {"flats.0.product_number": 1}},
        {
            $facet: {
                newProducts: [{$match: {"flats.new": 1}}, {$limit: 12}],
                featuredProducts: [{$match: {"flats.featured": 1}}, {$limit: 12}],
                thirdSectionProducts: [{$match: {"flats.third_section": 1}}, {$limit: 12}],
            }
        },
        {
            $project: {
                combinedProducts: {$concatArrays: ["$newProducts", "$featuredProducts", "$thirdSectionProducts"]}
            }
        },
        {$unwind: "$combinedProducts"},
        {$replaceRoot: {newRoot: "$combinedProducts"}},
        {
            $addFields: {
                type: {
                    $cond: {
                        if: {$eq: ["$$CURRENT._id", "$$ROOT.newProducts._id"]},
                        then: "new",
                        else: {
                            $cond: {
                                if: {$eq: ["$$CURRENT._id", "$$ROOT.featuredProducts._id"]},
                                then: "featured",
                                else: "third"
                            }
                        },
                    },
                },
            }
        },
        {
            $addFields: {
                flats: {
                    $filter: {
                        input: "$flats",
                        as: "flat",
                        cond: {$eq: ["$$flat.locale", locale]}
                    }
                },
                selectedRate: +selectedRate?.exchange_rate?.rate || 1
            }
        },
        {
            $addFields: {
                flats: {
                    $map: {
                        input: "$flats",
                        as: "flat",
                        in: {
                            $mergeObjects: [
                                "$$flat",
                                {
                                    price: {$multiply: ["$$flat.price", "$selectedRate"]},
                                    min_price: {$multiply: ["$$flat.min_price", "$selectedRate"]},
                                    max_price: {$multiply: ["$$flat.max_price", "$selectedRate"]},
                                    special_price: {$multiply: ["$$flat.special_price", "$selectedRate"]}
                                }
                            ]
                        }
                    }
                }
            }
        },
    ])
}
const getInfiniteScrollValue = async (models) => await Get_Exact_Core_Config(models, settingConstants.Infinite_Scroll)

const handleConfigPrice = (element, selectedRate, selectedLocale) => {
    const minimalPrice = Math.min(...new Set(element.variants.map(el => {
        const variantProduct = productCurrencyToggle(el, selectedRate, selectedLocale)
        return variantProduct.flats[0].min_price
    })));
    return {
        ...element,
        flats: [{
            ...element.flats[0],
            min_price: minimalPrice,
            price: minimalPrice,
        }],
    }
}

/*** NEW FEATURED/NEW PRODUCTS HOME PAGE ***/
const Get_New_And_Featured_Products = async (opts, models) => {
    const {locale, selectedRate, isMobile} = opts;

    const filterUniqueObjects = (products, type) => {
        const result = []
        const ids = new Set(products.filter(el => el.flats[0][type] === 1).map(el => el.id))

        for (let elem of ids) {
            result.push(products.find(el => el.id === elem))
        }

        return result
    }

    try {
        const fulfilledNewFeaturedProducts = await getNewFeatured(models, locale, selectedRate, isMobile)
        const allProducts = fulfilledNewFeaturedProducts.map(el => !!el.variants.length ? handleConfigPrice(el, selectedRate, locale) : el)

        const newProducts = filterUniqueObjects(allProducts, "new")
        const featuredProducts = filterUniqueObjects(allProducts, "featured")
        const thirdSectionProducts = filterUniqueObjects(allProducts, "third_section")

        // const newProducts = allProducts.splice(0, 8)
        // const featuredProducts = allProducts.splice(-8)
        const allBlockTitles = await models.attributes.find({code: {$in: ["new", "featured", "third_section"]}});
        const {translations: blockIntl_new} = allBlockTitles[0]
        const {translations: blockIntl_featured} = allBlockTitles[1]
        const {translations: blockIntl_third} = allBlockTitles[2] || {}
        const {name: translatedBlockTitleNew = ""} = blockIntl_new.find(el => el.locale === locale) || {}
        const {name: translatedBlockTitleFeatured = ""} = blockIntl_featured.find(el => el.locale === locale) || {}
        const {name: translatedBlockTitleThird = ""} = blockIntl_third?.find(el => el.locale === locale) || {}
        const productFinalResultHandle = (productList, blockTitle) => {
            return parseClone(productList.map(elem => {
                return {
                    ...elem,
                    flats: [{
                        ...elem.flats[0],
                        name: elem.flats[0].name ?? "",
                        price: Number(elem.flats[0].price).toFixed(2),
                        special_price: !!elem.flats[0].special_price ? Number(elem.flats[0].special_price).toFixed(2) : null,
                        min_price: Number(elem.flats[0].min_price).toFixed(2),
                        short_description: elem.flats[0].short_description?.replace(/<\/?[^>]+>/gi, "")
                    }],
                    block_title: blockTitle,
                    images: elem.images.length > 0
                        ? elem.images.map(el => `/storage/medium/${el}`)
                        : ["/_next/static/media/defaultpic.119a8e64.webp"]
                }
            }))
        }
        return {
            newProducts: productFinalResultHandle(newProducts, translatedBlockTitleNew),
            featuredProducts: productFinalResultHandle(featuredProducts, translatedBlockTitleFeatured),
            thirdSectionProducts: productFinalResultHandle(thirdSectionProducts, translatedBlockTitleThird)
        };
    } catch (err) {
        throw err;
    }
}

const getAllProducts = async (models, locale, query, findAllAttributes, selectedRate) => {
    const allowResponse = await allowOutOfStock(models);
    if (!allowResponse) return null

    const products = await models.products_v2.find({});
    let setMinPrice, setMaxPrice, confMinPrice, confMaxPrice, min, max, sortedProductsAsc;

    if (products.length === 1) {
        setMinPrice = setMaxPrice = products.map((item) => findMinimalOrMaximalPrice(item.flats[0], -1)).sort((a, b) => a - b)[0]
    } else {
        setMinPrice = products.map((item) => findMinimalOrMaximalPrice(item.flats[0], -1)).sort((a, b) => a - b)[0]
        setMaxPrice = products.map((item) => findMinimalOrMaximalPrice(item.flats[0], 1)).sort((a, b) => b - a)[0]
    }

    products.map((item) => {
        if (item.products?.length > 0) { // @ts-ignore
            confMinPrice = item.products?.map((el) => findMinimalOrMaximalPrice(el.flats[0], -1)).sort((a, b) => a - b)[0]
            confMaxPrice = item.products?.map((el) => findMinimalOrMaximalPrice(el.flats[0], 1)).sort((a, b) => b - a)[0]
        }
        item.min_price = confMinPrice;
    })

    if ((Number(confMinPrice) > Number(setMinPrice)) || !isNaN(Number(setMinPrice))) {
        min = setMinPrice
    } else {
        min = confMinPrice
    }

    if ((Number(confMaxPrice) > Number(setMaxPrice)) || isNaN(Number(setMaxPrice))) {
        max = confMaxPrice
    } else {
        max = setMaxPrice
    }
    const findItem = findFlatsExactLocale(products, locale)
    const addAttrInFlats = findItem.map(i => {
        const findItemAttr = findAllAttributes.find(elem => elem.product_id === i.id)

        if (findItemAttr) {
            const updatedFlat = {
                ...i.flats[0],
            };

            for (const key in findItemAttr) {
                if (findItemAttr.hasOwnProperty(key)) {
                    updatedFlat[key] = findItemAttr[key];
                }
            }

            return {
                ...i,
                flats: [updatedFlat],
            };
        }
        return i
    })
    const updateQuantityRes = await addMinQuantityController(models, addAttrInFlats)
    const updatedProducts = imageAddFields(updateQuantityRes)

    if (query?.sortByPrice === "asc") {
        sortedProductsAsc = updatedProducts.sort((a, b) => a.flats[0].min_price - b.flats[0].min_price)
    } else if (query?.sortByPrice === "desc") {
        sortedProductsAsc = updatedProducts.sort((a, b) => b.flats[0].min_price - a.flats[0].min_price)
    } else {
        sortedProductsAsc = updatedProducts
    }


    return {
        data: sortedProductsAsc.map(el => {
            const currHandleBody = {
                ...productCurrencyToggle({
                    ...el,
                    flats: [{
                        ...el.flats[0],
                        price: Number(el.flats[0].price).toFixed(2),
                        special_price: !!el.flats[0].special_price ? Number(el.flats[0].special_price).toFixed(2) : null,
                        min_price: Number(el.flats[0].min_price).toFixed(2),
                    }],
                }, selectedRate, locale),
            }
            if (!!el.variants.length) {
                return handleConfigPrice(currHandleBody, selectedRate, locale)
            }
            return currHandleBody
        }),
        dispatches: {
            setInitialMinPrice: min || 0, setInitialMaxPrice: max || 0,
        },
    };
}

const getSearchProducts = async (models, locale, query, selectedRate) => {
    const allowResponse = await allowOutOfStock(models);
    if (allowResponse) {
        const products = await models.products_v2.find({});
        const updatedProducts = findFlatsExactLocale(products, locale)
            .filter((elem) => {
                if (query && elem.flats && elem.flats?.[0]?.name) {
                    return (
                        (query && elem.flats?.[0]?.name.toLowerCase().includes(query.toLowerCase())) ||
                        (query && elem.sku.toLowerCase().includes(query.toLowerCase()))
                    )
                }

            })
            .filter(el => el.flats.find(elem => elem.locale === locale).status === 1)
        const updateQuantityRes = await addMinQuantityController(models, updatedProducts)
        return updateQuantityRes
            .map(el => {
                const mainBody = {
                    ...productCurrencyToggle({
                        ...el,
                        flats: [{
                            ...el.flats[0],
                            price: Number(el.flats[0].price).toFixed(2),
                            special_price: !!el.flats[0].special_price ? Number(el.flats[0].special_price).toFixed(2) : null,
                            min_price: Number(el.flats[0].min_price).toFixed(2),
                        }],
                    }, selectedRate, locale),
                    images: el.images.length > 0
                        ? el.images.map(elem => `/storage/${elem}`)
                        : ["/_next/static/media/defaultpic.119a8e64.webp"]
                }
                if (!!el.variants.length) {
                    return handleConfigPrice(mainBody, selectedRate, locale)
                }
                return mainBody
            })
    }
}

/*** NEW SEARCH LOGIC ***/
const Get_Searched_Products = async (models, locale, query, selectedRate) => {
    try {
        return await getSearchProducts(models, locale, query, selectedRate)
    } catch (err) {
        throw err;
    }
}

async function Get_All_Product(models, locale, query, findAllAttributes, selectedRate) {
    try {
        return await getAllProducts(models, locale, query, findAllAttributes, selectedRate)
    } catch (err) {
        throw err
    }
}

/*** NEW PRODUCT INNER PAGE ***/
const Get_Product_For_Product_Inner_Page = async (productSlug, options, models) => {
    const {locale, selectedRate} = options;
    try {
        const allowResponse = await allowOutOfStock(models);
        if (!allowResponse) {
            return {notFound: true};
        }
        const sku = await Get_Needed_Attribute(models, "sku", "sku_option")
        const shDesc = await Get_Needed_Attribute(models, "short_description", "short_description_option")
        const desc = await Get_Needed_Attribute(models, "description", "description_option")
        const findLocale = (attr) => attr?.translations?.find(({locale: trLocale}) => trLocale === locale)
        const productSkuName = findLocale(sku?.sku_option?.[0]) || "SKU";
        const productDescName = findLocale(desc?.description_option[0]) || "";
        const productShortDescName = findLocale(shDesc?.short_description_option[0]) || "";
        const data = await models.products_v2.find({
            "flats.locale": locale,
            "flats.url_key": productSlug,
            "flats.status": 1,
        })
        const res = findFlatsExactLocale(data, locale)
        const newRes = parseClone(res);
        const findVariantsWithLocale = newRes[0]?.variants ? findFlatsExactLocale(newRes[0]?.variants, locale) : [];
        const yoast_products = await models.yoast_products.find({locale, product_id: newRes[0]?.id})
        const [{
            yoast_facebook_description: yfd = "",
            yoast_facebook_title: yft = "",
            yoast_twitter_description: ytd = "",
            yoast_twitter_title: ytt = "",
            yoast_focus_keyword: yfk = ""
        } = {}] = parseClone(yoast_products);
        const updatedYoasts = [{
            yoast_facebook_description: !!yfd ? yfd : newRes[0]?.flats[0]?.meta_description,
            yoast_facebook_title: !!yft ? yft : newRes[0]?.flats[0]?.meta_title,
            yoast_twitter_description: !!ytd ? ytd : newRes[0]?.flats[0]?.meta_description,
            yoast_twitter_title: !!ytt ? ytt : newRes[0]?.flats[0]?.meta_title,
            yoast_focus_keyword: !!yfk ? yfk : newRes[0]?.flats[0]?.meta_keywords
        }]
        const updatedResWithCurr = [{
            ...newRes[0],
            variants: findVariantsWithLocale.map(el => productCurrencyToggle(el, selectedRate, locale)),
            yoast_products: updatedYoasts
        }]
        let variantsIndex = newRes[0]?.variants.map((el) => el.id);
        const variantsAttr = await models.product_attribute_values.find({product_id: {$in: variantsIndex}});
        const parsedVariantsAttr = parseClone(variantsAttr)
        const productSuperAttributes = await models.product_super_attributes.find({product_id: data[0]?.id})
        const parsedSuperAttributes = parseClone(productSuperAttributes)
        const superAttrIds = parsedSuperAttributes.map(item => item.attribute_id)
        const attributes = await models.attributes.find({id: {$in: superAttrIds}});
        const attributeOptionsAttr = await models.attribute_options.find({attribute_id: {$in: superAttrIds}});
        const parsedAttributes = parseClone(attributes)
        const parsedAttributesOptions = parseClone(attributeOptionsAttr)
        const productAttributeValues = await models.product_attribute_values.find({product_id: data[0]?.id});
        const parsedProductAttributeValues = parseClone(productAttributeValues)
        const attributeIds = parsedProductAttributeValues.map(item => item.attribute_id);
        const attributesDetail = await models.attributes.find({id: {$in: attributeIds}});
        const parsedAttributesDetail = parseClone(attributesDetail)
        const attributeOptions = await models.attribute_options.find({attribute_id: {$in: attributeIds}});
        const parsedAttributeOptions = parseClone(attributeOptions)
        const handleVariants = (id) => parsedVariantsAttr.filter((variant) => variant.product_id === id);
        const attributesRes = parsedAttributes.map((attribute) => {
            const matchingOptions = parsedAttributesOptions.filter((option) => option.attribute_id === attribute.id);
            const attributeOptions = matchingOptions.map((option) => ({
                ...option,
                attribute: {
                    ...attribute,
                    translations: [attribute?.translations?.find(({locale: itemLoc}) => itemLoc === locale) || {}]
                },
            }));

            return {
                ...attribute,
                attribute_options: attributeOptions.map((elem) => {
                    return {
                        ...elem,
                        swatch_value: colorType(elem.swatch_value),
                        colorsStyle: elem.swatch_value ?? null,
                        translations: [elem?.translations?.find(({locale: itemLoc}) => itemLoc === locale) || {}]
                    }
                }).sort(({sort_order: sortA}, {sort_order: sortB}) => sortA - sortB)
            };
        });
        const resultAttributes = attributesRes.map((elem) => {
            const matchingOptions = parsedSuperAttributes.filter((option) => {
                return option.attribute_id === elem.id;
            });
            return {
                attributes_values: [elem],
                ...matchingOptions[0]
            }
        })
        const updatedResultAttributes = resultAttributes.map(({attribute_id, product_id, _id, ...attributes}) => {
            const {attributes_values: [{attribute_options}], ...selectedAttributeObj} = attributes;
            const [selectedAttribute] = Object.keys(selectedAttributeObj);
            return {
                ...attributes,
                attribute_options: attribute_options.filter(({id}) => attributes[selectedAttribute].some(el => el === id))
            }
        })
        const exceptArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 26, 27, 29]

        const filterDetails = (arr) => {
            return arr.map(item => {
                const attribute = parsedAttributesDetail.filter(attr => attr.id === item.attribute_id).map(el => {
                    return {
                        ...el,
                        ...el.translations.find(elem => elem.locale === locale)
                    }
                });
                const options = parsedAttributeOptions.filter(option => option.attribute_id === item.attribute_id);
                return {
                    ...item,
                    attribute,
                    attribute_options: options
                };
            })
                .filter(el => exceptArray.every(elem => el.attribute_id !== elem))
                .map(el => {
                    const attributeOptionsFinder = (value) => {
                        return el.attribute_options.find(elem => String(elem.id) === String(value))?.translations.find(el => el.locale === locale)?.label
                    }
                    const attributeTypeHandler = {
                        select: attributeOptionsFinder(el.integer_value),
                        text: attributeOptionsFinder(el.text_value),
                        textarea: attributeOptionsFinder(el.text_value),
                        datetime: el.datetime_value,
                        date: el.date_value,
                        price: el.float_value ? `${(+el.float_value).toFixed(2)}$` : el.float_value,
                        boolean: el.boolean_value,
                        multiselect: el.text_value?.split(',').map(value => attributeOptionsFinder(value)).filter(elem => !!elem).join(', '),
                        checkbox: el.text_value?.split(',').map(value => attributeOptionsFinder(value)).filter(elem => !!elem).join(', '),
                        image: el.text_value,
                        file: el.text_value
                    }

                    return {
                        ...el,
                        attribute_name: el.attribute[0]?.name || el.attribute[0]?.code || null,
                        attribute_value: attributeTypeHandler[el.attribute[0]?.type] || null
                    }
                })
                .filter(el => {
                    return +el.attribute?.[0]?.is_visible_on_front === 1 && !!el.attribute_value
                })
        }

        if (!res || res.length === 0) {
            return []
        }
        const modifiedRes = await Promise.all(updatedResWithCurr.map(async (item) => {
            try {
                const minQuantityVal = await addMinQuantityController(models, item.variants);
                const productOptions = new Promise((resolve, reject) => {
                    models.product_options.find({product_id: item.id}).exec((err, productOptions) => {
                        if (err) {
                            reject(err);
                            return;
                        }

                        models.product_option_values.find().exec((err, productOptionValues) => {
                            if (err) {
                                reject(err);
                                return;
                            }

                            const options = productOptions.map(option => {
                                const children = productOptionValues
                                    .filter(value => value.product_option_id === option.id)
                                    .map(value => {
                                        let label = value.label;
                                        if (value.translations && value.translations.length > 0) {
                                            const translation = value.translations.find(trans => trans.locale === locale);
                                            if (translation) {
                                                label = translation.label;
                                            }
                                        }

                                        return {
                                            id: value.id,
                                            label: label,
                                            admin_name: value.admin_name,
                                            price: value.price,
                                            sort_order: value.sort_order
                                        };
                                    });

                                return {
                                    id: option.id,
                                    title: option.title,
                                    product_id: option.product_id,
                                    required: option.required,
                                    type: option.type,
                                    children: children
                                };
                            });

                            resolve(options);
                        });
                    });
                });
                const variantIdExistToggle = () => {
                    const {qty: [maxVariantQuantity = 0] = []} = item.variants
                        ?.find(({id: variantId}) => variantId === Number(item.additional?.default_variant_id)) || {};
                    return maxVariantQuantity;
                }
                const productElementOptions = {
                    sku_option: productSkuName,
                    short_description_option: productShortDescName,
                    description_option: productDescName,
                    details: filterDetails(parsedProductAttributeValues),
                    attributes: updatedResultAttributes,
                    product_options: JSON.parse(JSON.stringify(await productOptions)),
                }

                item.variants = minQuantityVal.map((el) => {
                    el.product_attribute_values = handleVariants(el?.id);
                    el.details = filterDetails(el?.product_attribute_values);

                    return {
                        ...el,
                        maxQty: variantIdExistToggle(),
                        ...productElementOptions
                    };
                });

                // if (item.images.length > 0) {
                //     item.images.map((x, ind) => {
                //         item.images[ind] = makeImageClone(x);
                //     });
                // }
                return {
                    ...item,
                    ...productElementOptions
                };
            } catch (error) {
                console.error(error);
                return item;
            }
        }));

        const allowProduct = filterAllAllowedProducts(modifiedRes, allowResponse)
        if (!allowProduct || allowProduct?.length === 0) return {notFound: true}
        const updateQuantityRes = await addMinQuantityController(models, allowProduct)
        const updatedWithCurrProduct = updateQuantityRes.map(curr => productCurrencyToggle(curr, selectedRate, locale))
        return updatedWithCurrProduct.map((element) => {
            const replaceDesc = (str) => {
                if (!str) return str;
                const tableRegex = /<table[\s\S]*?<\/table>/g;
                const tables = str.match(tableRegex);

                if (!tables) return str;

                tables.forEach(table => {
                    const wrapper = `<div className="table_wrapper" style="overflow: scroll">${table}</div>`;
                    str = str.replace(table, wrapper);
                });
                return str;
            };

            const stripTags = (html) => {
                return html?.replace(/<[^>]*>/g, '');
            }
            const minimalPriceHandle = priceKey => element.type === "configurable"
                ? Math.min(...new Set(element.variants.map(el => el.flats[0].min_price)))
                : element.flats[0][priceKey]

            return {
                ...element,
                attributes: updatedResultAttributes,
                images: element.images.length > 0
                    ? element.images.map(elem => `/storage/${elem}`)
                    : ["/_next/static/media/defaultpic.119a8e64.webp"],
                flats: [{
                    ...element.flats[0],
                    short_description: replaceDesc(element.flats[0].short_description),
                    description: replaceDesc(element.flats[0].description),
                    min_qty: element.flats[0].min_qty ?? 0,
                    meta_title: !!element.flats[0].meta_title ? element.flats[0].meta_title : element.flats[0].name,
                    meta_description: !!element.flats[0].meta_description ? element.flats[0].meta_description : stripTags(element.flats[0].short_description),
                    price: minimalPriceHandle("price"),
                    min_price: minimalPriceHandle("min_price")
                }],
                yoast_products: element.yoast_products.map((el) => {
                    return {
                        ...el,
                        yoast_facebook_title: el.yoast_facebook_title !== "" ? el.yoast_facebook_title : element.flats[0].meta_title ? element.flats[0].meta_title : element.flats[0].name,
                        yoast_facebook_description: el.yoast_facebook_description !== "" ? el.yoast_facebook_description : element.flats[0].meta_description ? element.flats[0].meta_description : stripTags(element.flats[0].short_description),
                        yoast_twitter_title: el.yoast_twitter_title !== "" ? el.yoast_twitter_title : element.flats[0].meta_title ? element.flats[0].meta_title : element.flats[0].name,
                        yoast_twitter_description: el.yoast_twitter_description !== "" ? el.yoast_twitter_description : element.flats[0].meta_description ? element.flats[0].meta_description : stripTags(element.flats[0].short_description),
                    }
                }),
                variants: element.variants.map(el => {
                    return {
                        ...el,
                        flats: [{
                            ...el.flats[0],
                            price: Number(el.flats[0].price).toFixed(2),
                            special_price: !!el.flats[0].special_price ? Number(el.flats[0].special_price).toFixed(2) : null,
                            min_price: Number(el.flats[0].min_price).toFixed(2),
                        }],
                        images: !!el.images.length
                            ? el.images.map(elem => `/storage/${elem}`)
                            : ["/_next/static/media/defaultpic.119a8e64.webp"],
                    }
                })
            }
        })
    } catch (error) {
        throw error;
    }
    // TODO!!! bundle product
    // if (updatedResWithCurr[0]?.type === 'bundle') {
    //     const modifiedRes = updatedResWithCurr.map((item) => {
    //         let base_image;
    //         item.bundle_products.map((itemPr) => {
    //             itemPr.bundle_product_options.map((el) => {
    //
    //                 if (el.images.length > 0) {
    //                     el.images.map((y, ind) => {
    //                         base_image = makeImageClone(y)
    //                         el.images[ind] = base_image
    //                     })
    //                 }
    //                 if (el.flats[0].images.length > 0) {
    //                     el.flats[0].images.map((y, ind) => {
    //                         base_image = makeImageClone(y)
    //                         el.flats[0].images[ind] = base_image
    //                     })
    //                 }
    //                 // return el
    //                 return {
    //                     ...el, sku_option: productSkuName, short_description_option: productShortDescName, description_option: productDescName,
    //                     details: filterDetails(parsedProductAttributeValues),
    //                     attributes: resultAttributes
    //                 }
    //             })
    //             return itemPr
    //         })
    //         if (item.images.length > 0) {
    //             item.images.map((x, ind) => {
    //                 base_image = makeImageClone(x)
    //                 item.images[ind] = base_image
    //             })
    //
    //         }
    //         // return item
    //         return {
    //             ...item, sku_option: productSkuName, short_description_option: productShortDescName, description_option: productDescName,
    //             details: filterDetails(parsedProductAttributeValues),
    //             attributes: resultAttributes
    //         }
    //     })
    //     const finalResult = modifiedRes.map(el => productCurrencyToggle(el, selectedRate, locale))
    //     resolve(finalResult)
    // }
};
/*** NEW RELATED PRODUCTS ***/
// WITH --> router get !!!!!!!!!!!
const Get_Related_Products = async (options, models) => {
    const {locale, limit, category_id, product_id, currency} = options;
    let productIds = [];

    try {
        const allowResponse = await allowOutOfStock(models);
        if (!allowResponse) {
            return false
        } else {
            const sku = await Get_Needed_Attribute(models, "sku", "sku_option")
            const shDesc = await Get_Needed_Attribute(models, "short_description", "short_description_option")
            const desc = await Get_Needed_Attribute(models, "description", "description_option")
            const parsedSku = parseClone(sku)
            const parsedShDesc = parseClone(shDesc)
            const parsedDesc = parseClone(desc)
            const productRelations = await models.products_relations.find({parent_id: {$in: [product_id]}});
            if (productRelations.length > 0) {
                productIds = productRelations.map((e) => e.child_id);
            } else {
                const productsCategories = await models.products_categories.find({category_id: {$in: category_id}});
                productIds = productsCategories.map((e) => e.product_id);
            }
            const query = {
                "flats.locale": locale,
                "flats.status": 1,
                id: {$in: productIds},
                parent_id: null,
            }

            const res = await
                models
                    .products_v2
                    .find(query)
                    .limit(limit)

            const options = {
                ...parsedSku,
                ...parsedShDesc,
                ...parsedDesc,
            }
            const clonedRes = parseClone(res);
            const filteredProductFlats = findFlatsExactLocale(clonedRes, locale)
            const updateQuantityRes = await addMinQuantityController(models, filteredProductFlats)
            // const modifiedRes = imageAddFields(updateQuantityRes, options)
            return filterAllAllowedProducts(updateQuantityRes, allowResponse)
                .map(el => {
                    const mainBody = {
                        ...productCurrencyToggle({
                            ...el,
                            flats: [{
                                ...el.flats[0],
                                price: Number(el.flats[0].price).toFixed(2),
                                special_price: !!el.flats[0].special_price ? Number(el.flats[0].special_price).toFixed(2) : null,
                                min_price: Number(el.flats[0].min_price).toFixed(2),
                            }],
                        }, currency, locale),
                        images: el.images.length > 0
                            ? el.images.map(elem => `/storage/${elem}`)
                            : ["/_next/static/media/defaultpic.119a8e64.webp"]
                    }
                    if (!!el.variants.length) {
                        return handleConfigPrice(mainBody, currency, locale)
                    }
                    return mainBody
                })
                .filter(el => el.id !== product_id)
            // return currencyUpdateRes.filter(({ flats: [{ product_id = 0 } = {}] = [] }) => product_id !== productId)
        }

    } catch (err) {
        throw err;
    }
};
/*** NEW UPSELL PRODUCTS ***/

//AFTER ALL IS DONE -> WILL DO
// getUpOrCrossProd
const Get_Up_Sell_Products = async (options, models) => {
    const {locale, limit, product_id, rate, symbol} = options;
    const selectedRate = {
        exchange_rate: {
            rate,
        },
        symbol,
    }
    try {
        const allowResponse = await allowOutOfStock(models);
        if (allowResponse) {
            const res = await models.product_up_sells.find({parent_id: {$in: product_id.split(",")}});

            if (res.length > 0) {
                const productIds = res.map((e) => e.child_id);
                const query = {
                    "flats.locale": locale,
                    "flats.status": 1,
                    id: {$in: productIds},
                    parent_id: null
                }
                const products = await
                    models
                        .products_v2
                        .find(query)
                        .limit(Number(limit));

                const result = parseClone(products)
                const newProducts = findFlatsExactLocale(result, locale);
                const modifiedRes = imageAddFields(newProducts)
                const finalResultCurr = modifiedRes.map(el => productCurrencyToggle(el, selectedRate, locale))
                const updateQuantityRes = await addMinQuantityController(models, finalResultCurr)
                return filterAllAllowedProducts(updateQuantityRes, allowResponse);
            } else {
                return res;
            }
        }

    } catch (error) {
        throw error;
    }
};
//AFTER ALL IS DONE -> WILL DO
// getUpOrCrossProd
/*** NEW CROSS-SELL PRODUCTS ***/
const Get_Cross_Sell_Products = async (options, models) => {
    const {locale, limit, product_id, rate, symbol} = options;
    const selectedRate = {
        exchange_rate: {
            rate,
        },
        symbol,
    }
    try {
        const allowResponse = await allowOutOfStock(models);
        if (allowResponse) {

            const res = await models.product_cross_sells.find({parent_id: {$in: product_id.split(",").map(Number)}});

            if (res.length > 0) {
                const productIds = res.map((e) => e.child_id);
                const query = {
                    "flats.locale": {$in: [locale, "en"]},
                    "flats.status": 1,
                    id: {$in: productIds},
                    parent_id: null
                }
                const result = await
                    models
                        .products_v2
                        .find(query)
                        .limit(Number(limit));
                const localeProducts = findFlatsExactLocale(result, locale);
                const modifiedRes = imageAddFields(localeProducts)
                const randomProducts = [];
                const newProducts = [];

                while (randomProducts.length < modifiedRes.length) {
                    let r = Math.floor(Math.random() * modifiedRes.length);
                    if (randomProducts.indexOf(r) === -1) randomProducts.push(r);
                }

                for (let i = 0; i < modifiedRes.length; i++) {
                    newProducts.push(modifiedRes[randomProducts[i]]);
                }
                const updateQuantityRes = await addMinQuantityController(models, newProducts)
                const updatedResult = updateQuantityRes.map(el => productCurrencyToggle(el, selectedRate, locale))

                return filterAllAllowedProducts(updatedResult, allowResponse, 100);
            } else {
                return res;
            }
        }
    } catch (error) {
        throw error;
    }
};
const Get_Product_Options = (options, models) => {
    const {locale} = options;

    return allowOutOfStock(models).then(allowResponse => {
        return new Promise((resolve, reject) => {
            models.product_options.find().exec((err, productOptions) => {
                if (err) {
                    reject(err);
                    return;
                }

                models.product_option_values.find().exec((err, productOptionValues) => {
                    if (err) {
                        reject(err);
                        return;
                    }

                    const options = productOptions.map(option => {
                        const children = productOptionValues
                            .filter(value => value.product_option_id === option.id)
                            .map(value => {
                                let label = value.label;
                                if (value.translations && value.translations.length > 0) {
                                    const translation = value.translations.find(trans => trans.locale === locale);
                                    if (translation) {
                                        label = translation.label;
                                    }
                                }

                                return {
                                    id: value.id,
                                    label: label,
                                    admin_name: value?.admin_name,
                                    price: value.price,
                                    sort_order: value.sort_order
                                };
                            });

                        return {
                            id: option.id,
                            title: option.title,
                            product_id: option.product_id,
                            required: option.required,
                            type: option.type,
                            children: children
                        };
                    });

                    resolve(options);
                });
            });
        });
    });
};

/*** NEW CATEGORY PRODUCT PAGE ***/
const Get_New_Category_Products = async (slug, locale, limit, models, page = 1, sortBy, findAllAttributes, isInfinite, filterValues, selectedRate) => {
    try {
        const response = await allowOutOfStock(models);
        let resolveCategories = {};
        let queryParams = {
            "flats.locale": locale,
            "flats.status": 1,
        }
        if (slug) {
            resolveCategories = await models.categories.findOne({slug});
            if (!resolveCategories || resolveCategories.length === 0) {
                return {notFound: true};
            }
            queryParams.category_ids = resolveCategories.id
        }
        const allItems = await models.products_v2.find(queryParams)

        const parsedAllItems = parseClone(allItems);
        const objectPropertyHandle = (prId, findItemAttr) => {
            const AttrKeysArr = Object.keys(findItemAttr).slice(1);
            const AttrValuesArr = Object.values(findItemAttr).slice(1);
            const indexPrice = AttrKeysArr.findIndex(indexPriceKey => indexPriceKey === "price")
            if (indexPrice > -1) {
                AttrKeysArr.splice(indexPrice, 1)
                AttrValuesArr.splice(indexPrice, 1)
            }
            return AttrKeysArr.reduce((acc, elemKey, indexKey) => {
                if (
                    findItemAttr.hasOwnProperty(elemKey)
                    && filterValues[elemKey].split(",").find(attrValueI => {
                        if (Array.isArray(AttrValuesArr[indexKey])) {
                            return AttrValuesArr[indexKey].some(elemString => String(elemString) === String(attrValueI))
                        }
                        return attrValueI === AttrValuesArr[indexKey]
                    })
                    && acc
                ) {
                    acc.flats[0][elemKey] = findItemAttr[elemKey];
                    return acc;
                }
            }, {...prId})
        }
        const addAttrInFlats = parsedAllItems.map(i => {
            const findItemAttr = findAllAttributes?.find(elem => elem.product_id === i.id)
            if (findItemAttr) {
                objectPropertyHandle(i, findItemAttr)
            }
            return i;
        }).filter(F => F !== undefined)
        const updateQuantityRes = await addMinQuantityController(models, addAttrInFlats)
        const filteredProductFlats = findFlatsExactLocale(updateQuantityRes, locale)
        const updatedCurrResult = filteredProductFlats.map(el => {
            const mainBody = {
                ...productCurrencyToggle({
                    ...el,
                    flats: [{
                        ...el.flats[0],
                        price: Number(el.flats[0].price).toFixed(2),
                        special_price: !!el.flats[0].special_price ? Number(el.flats[0].special_price).toFixed(2) : null,
                        min_price: Number(el.flats[0].min_price).toFixed(2),
                    }],
                }, selectedRate, locale),
                images: el.images.length > 0
                    ? el.images.map(elem => `/storage/${elem}`)
                    : ["/_next/static/media/defaultpic.119a8e64.webp"]
            }
            if (!!el.variants.length) {
                return handleConfigPrice(mainBody, selectedRate, locale)
            }
            return mainBody
        })
        const finalCatProductsResult = updatedCurrResult
            .sort((a, b) => b.id - a.id)
            .sort((a, b) =>
                (sortBy && a.flats[0].min_price - b.flats[0].min_price)
                || (sortBy === false && b.flats[0].min_price - a.flats[0].min_price));

        return buildNeededData(
            finalCatProductsResult,
            response,
            locale,
        );
    } catch (error) {
        console.log(error, "___THIS IS ERROR MESSAGE FROM CATCH!!!")
    }
};

const Get_Filtered_Category_Products = async (models, responseProductList, filterValues, {
    page,
    limit,
    selectedExchangeRate
}) => {
    const valueRes = await getInfiniteScrollValue(models);
    let isInfiniteScroll = false;
    if (valueRes) {
        isInfiniteScroll = Boolean(Number(valueRes));
    }
    const {data: responseData} = responseProductList || {}
    const newFilterData = [];
    if (Object.keys(filterValues).length > 0) {
        responseData.forEach(product => {
            const {flats, variants, type: configOrOtherType} = product
            const configProduct = configOrOtherType === "configurable"
            if (
                filterBy(flats?.[0], filterValues, configProduct, configProduct ? variants : responseData)
                && (product.parent_id ? product.flats[0].visible_individually === 1 : true)
            ) {
                newFilterData.push({
                    ...product,
                    variants: product.variants.filter((item) => item.flats[0].visible_individually === 1)
                });
            }
        });
    } else {
        responseData.forEach(resElement => {
            // const {flats: priceFlat} = resElement
            // const [{min_price, max_price, special_price, price}] = priceFlat
            if (resElement.parent_id ? resElement.flats[0].visible_individually === 1 : true) {
                newFilterData.push(
                    {
                        ...resElement,
                        // TODO !!! if we dont need this flat changer -> remove
                        // flats: [{
                        //     ...priceFlat?.[0],
                        //     min_price: parseFloat(min_price) * Number(selectedExchangeRate).toFixed(2),
                        //     max_price: parseFloat(max_price) * Number(selectedExchangeRate).toFixed(2),
                        //     special_price: parseFloat(special_price) * Number(selectedExchangeRate).toFixed(2),
                        //     price: parseFloat(price) * Number(selectedExchangeRate).toFixed(2)
                        // }],
                        variants: resElement.variants.filter((item) => item.flats[0].visible_individually === 1)
                    }
                )
            }
        });
    }
    const skipCount = page === 1 || isInfiniteScroll ? 0 : (page - 1) * limit;
    const finalLimit = isInfiniteScroll ? limit * page : limit;
    const paginatedResults = newFilterData.slice(skipCount, skipCount + finalLimit);
    const {length: filteredDataLength} = newFilterData;
    const {length: paginatedDataLength} = paginatedResults || [];

    return {
        ...responseProductList,
        total: filteredDataLength,
        pageCount: filteredDataLength > paginatedDataLength ? Math.ceil(filteredDataLength / limit) : 0,
        isInfiniteScroll: isInfiniteScroll,
        data: paginatedResults
    }
}

const Get_Scroll_Products = async (models, type, locale, limit) => {
    try {
        const allProducts = await getNewFeatured(models, type, locale, limit)
        return imageAddFields(allProducts)
    } catch (err) {
        throw err
    }
}

/*********************** CUSTOMER GROUP PRICE ***********************/

const Get_Customer_Groups = async (models) => {
    try {
        const res = await models.product_customer_group_prices.find({});
        return parseClone(res);
    } catch (err) {
        console.log(err, "Error in -> !__! Get_Customer_Groups !__!")
    }
}

/*********************** BUILDER REQUESTS OLD VERSION ********************/
//OLD VERSION With Router get -> without currency
const Get_All_Products = (models, locale) => {
    return allowOutOfStock(models)
        .then(response => {
            return new Promise((resolve, reject) => {
                models
                    .products
                    .aggregate([
                        {
                            $lookup: {
                                from: "product_super_attributes",
                                localField: "id",
                                foreignField: "product_id",
                                as: "variants",
                            }
                        },
                        {
                            $lookup: {
                                from: "product_flat",
                                localField: "id",
                                foreignField: "product_id",
                                as: "product_flat",
                                pipeline: [{$match: {locale}}]
                            }
                        },
                        {
                            $lookup: {
                                from: "product_inventories",
                                localField: "id",
                                foreignField: "product_id",
                                as: "product_inventories"
                            }
                        },
                        {
                            $lookup: {
                                from: "product_images",
                                localField: "id",
                                foreignField: "product_id",
                                as: "product_images",
                            }
                        },
                        {
                            $lookup: {
                                from: "products_categories",
                                localField: "id",
                                foreignField: "product_id",
                                as: "categories",
                            }
                        },
                        {
                            $addFields: {
                                status: {$arrayElemAt: ["$product_flat.status", 0]},
                            }
                        },
                        {
                            $match: {
                                $or: [
                                    {status: 1},
                                    {status: "1"},
                                ]
                            }
                        },
                        {
                            $lookup: {
                                from: "products",
                                localField: "id",
                                foreignField: "parent_id",
                                as: "products",
                                let: {id: "$id"},
                                pipeline: [
                                    {
                                        $lookup: {
                                            from: "product_flat",
                                            localField: "id",
                                            foreignField: "product_id",
                                            as: "product_flat",
                                            pipeline: [{$match: {locale}}]
                                        }
                                    },
                                    {
                                        $lookup: {
                                            from: "product_images",
                                            localField: "id",
                                            foreignField: "product_id",
                                            as: "product_images",
                                        }
                                    },
                                    {
                                        $addFields: {
                                            status: {$arrayElemAt: ["$product_flat.status", 0]},
                                        }
                                    },
                                    {
                                        $match: {
                                            $or: [
                                                {status: 1},
                                                {status: "1"},
                                            ]
                                        }
                                    },
                                ]
                            }

                        }
                    ])
                    .then((res) => {

                        let setMinPrice;
                        let setMaxPrice;
                        if (res.length === 1) {
                            setMinPrice = setMaxPrice = res.map((item) => findMinimalOrMaximalPrice(item.product_flat[0], -1)).sort((a, b) => a - b)[0]
                        } else {
                            setMinPrice = res.map((item) => findMinimalOrMaximalPrice(item.product_flat[0], -1)).sort((a, b) => a - b)[0]
                            setMaxPrice = res.map((item) => findMinimalOrMaximalPrice(item.product_flat[0], 1)).sort((a, b) => b - a)[0]
                        }
                        let confMinPrice
                        let confMaxPrice;
                        res.map((item) => {
                            if (item.products.length > 0) { // @ts-ignore
                                confMinPrice = item.products.map((el) => findMinimalOrMaximalPrice(el.product_flat[0], -1)).sort((a, b) => a - b)[0]
                                confMaxPrice = item.products.map((el) => findMinimalOrMaximalPrice(el.product_flat[0], 1)).sort((a, b) => b - a)[0]
                            }
                            item.min_price = confMinPrice;
                        })

                        let min
                        let max
                        if ((Number(confMinPrice) > Number(setMinPrice)) || !isNaN(Number(setMinPrice))) {
                            min = setMinPrice
                        } else {
                            min = confMinPrice
                        }

                        if ((Number(confMaxPrice) > Number(setMaxPrice)) || isNaN(Number(setMaxPrice))) {
                            max = confMaxPrice
                        } else {
                            max = setMaxPrice
                        }
                        const allowedProducts = filterAllAllowedProducts(res, response)

                        const everyThing = {
                            data: allowedProducts,
                            filters: [],
                            links: {},
                            max_price: setMaxPrice || 0,
                            meta: {},
                            total: 100,
                            dispatches: {
                                setInitialMinPrice: min || 0, setInitialMaxPrice: max || 0,
                            },
                        };

                        resolve(everyThing)
                    })
                    .catch(err => reject(err))
            })
        })
}

const getNewFeaturedBuilder = async (models, type, locale, limit, allowResponse) => {
    return new Promise((resolve, reject1) => {
        models
            .products
            .aggregate(featuredOrNewAggregate(type, locale, limit))
            .then((res) => {
                const modifiedRes = parseClone(res)
                const allowedProducts = filterAllAllowedProducts(modifiedRes, allowResponse)
                resolve(allowedProducts)
            })
            .catch((err) => reject1(err))
    })
}

function Get_New_And_Featured_Products_Builder(opts, models) {
    const {locale, limit} = opts
    return allowOutOfStock(models)
        .then(allowResponse => {
            return new Promise((resolve, reject) => {
                const newProducts = getNewFeaturedBuilder(models, "new", locale, limit, allowResponse)
                const featuredProducts = getNewFeaturedBuilder(models, "featured", locale, limit, allowResponse)
                const thirdSectionProducts = getNewFeaturedBuilder(models, "third_section", locale, limit, allowResponse)
                return Promise
                    .all([newProducts, featuredProducts, thirdSectionProducts])
                    .then((response) => {

                        let allHomeProducts = {
                            new: response[0],
                            featured: response[1],
                            third_section: response[2]
                        }
                        resolve(allHomeProducts);
                    })
                    .catch(err => reject(err));
            });
        })
}

/***************************  __ BUILDER_END __ ******************************/

module.exports = {
    Get_New_And_Featured_Products,
    Get_Product_For_Product_Inner_Page,
    Get_All_Products,
    Get_Related_Products,
    Get_Cross_Sell_Products,
    Get_Up_Sell_Products,
    Get_New_Category_Products,
    Get_Searched_Products,
    Get_All_Product,
    Get_Product_Options,
    Get_Scroll_Products,
    Get_New_And_Featured_Products_Builder,
    Get_Customer_Groups,
    Get_Filtered_Category_Products
}
